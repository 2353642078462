@import '../../Styles/settings';

.root {
  background: $near-white;
  padding: #{$mobile-header-height} 0px;
  &.noBottomPadding {
    padding-bottom: 0;
  }
}

.contentSection {
  margin-top: 32px;
  padding: 32px 0;
  background-color: $clear-blue;
}

.h2 {
  margin-bottom: 0;
  font-size: 20px;
}

.infoText {
  color: $white;
}

.orderInformationSection {
  border-radius: 8px;
  margin: 32px 15px;
  overflow: hidden;
}

.orderInformationList {
  padding: 0;
  list-style: none;
}

.listTitle {
  @include HelveticaMedium;
  background-color: $white;
  font-size: 20px;
  padding: 16px;
  margin-bottom: 2px;
}

.listAccordion {
  background-color: $white;
  font-size: 16px;
  padding: 16px;
  margin-bottom: 2px;
  &:last-of-type {
    margin-bottom: 0px;
  }
}

.accordionTitle {
  @include HelveticaMedium;
  color: $midnight-blue;
}

.accordionWrapper {
  display: flex;
  justify-content: space-between;
}

.slideDownOuter {
  height: 0;
  overflow-y: hidden;
  transition: height 0.3s $main-timing-function;
}

.slideDownInner {
  display: block;
  &.marginTop {
    margin-top: 12px;
  }
}

.slideDownExpanded {
  height: auto;
}

.infoContainer {
  display: flex;
  flex-direction: column;
}

.infoTitle {
  @include HelveticaMedium;
  margin-top: 12px;
}

.lightGray {
  color: $darkest-gray;
}

.relatedItemsSection {
  margin-top: 32px;
  &.noMarginTop {
    margin-top: 0;
  }
}

.lightGrayFlex {
  color: $darkest-gray;
  display: flex;
  justify-content: space-between;
  &.black {
    @include HelveticaMedium;
    color: $black;
    font-size: 18px;
    margin-top: 8px;
  }
}

@media #{$md-up} {
  .root {
    padding: 0 0 80px;
  }

  .contentSection {
    margin-top: 64px;
    background-color: $clear-blue;
    padding-top: 64px;
    padding-bottom: 80px;
  }

  .heroSection {
    margin-bottom: 64px;
  }

  .h1 {
    line-height: 64px;
    margin-bottom: 32px;
  }

  .h2 {
    font-size: 24px;
  }

  .cardTitle {
    @include HelveticaMedium;
    font-size: 24px;
  }

  .infoText {
    margin-bottom: 32px;
    max-width: 50%;
  }

  .textContainer {
    max-width: 1158px;
    margin: auto;
    padding: 65px 15px 55px 15px;
  }

  .linkContainer {
    flex-direction: row;
    column-gap: 24px;
    max-height: 40px;
  }

  .lightGrayFlex {
    &:not(:last-of-type) {
      margin-bottom: 12px;
    }
  }

  .relatedItemsSection {
    margin-top: 64px;
    &.noMarginTop {
      margin-top: 0;
    }
  }

  .productsTitleWrapper {
    border-bottom: 1px solid $bona-light-grey;
    margin-left: -45px;
    margin-right: -45px;
    padding: 0 45px;
    padding-bottom: 24px;
    margin-bottom: 25px;
  }
}

div[data-testid='survey-root'] > div {
  padding-top: 0 !important;
}
