@import '../../../Styles/settings';

.infoContainer {
  display: flex;
  flex-direction: column;
  color: $darkest-gray;
}

.infoTitle {
  @include HelveticaMedium;
  margin-top: 12px;
  color: $black;
}

.flex {
  display: flex;
  justify-content: space-between;
  &.black {
    @include HelveticaMedium;
    color: $black;
    font-size: 18px;
    margin-top: 8px;
  }
}

@media #{$md-up} {
  .infoText {
    margin-bottom: 32px;
    max-width: 50%;
  }

  .flex {
    &:not(:last-of-type) {
      margin-bottom: 12px;
    }
  }

  .cardTitle {
    @include HelveticaMedium;
    font-size: 24px;
    color: $black;
  }
}
